import React, { Component, Fragment } from "react";
import TicketService from "../../../components/services/TicketService";
import TicketMessageService from "../../../components/services/TicketMessageService";
import { connect } from "react-redux";
import { BsArrowLeft } from "react-icons/bs";
import { RoundButton } from "../../../components/common/RoundButton";
import { Pill } from "../../../components/common/Pill";
import { NavLink } from "react-router-dom";
import queryString from "query-string";
import { AWS_URL, ISSUE_TYPES } from "../../../components/config/constants";
import { ModalSolveTicket } from "../../../components/common/ModalSolveTicket";
import moment from "moment";
import PictureService from "../../../components/services/PictureService";
import DefaultAvatar from "../../../components/helper/DefaultAvatar";
import PictureHelper from "../../../components/helper/PictureHelper";
import AccountVerifyTick from "../../../components/common/AccountVerifyTick";

class SupportDetail extends Component {
  ticketService = null;
  ticketMessageService = null;
  pictureService = null;

  constructor(props) {
    super(props);

    this.observer = React.createRef();
    this.hiddenPictureInput = React.createRef();

    this.state = {
      loading: false,
      data: {},
      openSolve: false,
      messages: [],
      message: "",
      attachmentId: null,
      attachmentName: "",
    };
    this.queryParams = queryString.parse(window.location.search);

    this.ticketService = new TicketService();
    this.ticketMessageService = new TicketMessageService();
    this.pictureService = new PictureService();
  }

  componentDidMount = () => {
    this.loadData();
    this.loadMessages();
  };

  loadData = () => {
    this.ticketService.get(this.queryParams.id).then(async (res) => {
      if (res.status === 200) {
        this.setState({ data: res.data });
      }
    });
  };

  loadMessages = () => {
    this.ticketService.getMessages(this.queryParams.id).then(async (res) => {
      if (res.status === 200) {
        this.setState({ messages: res.data });
      }
    });
  };

  onSubmit = (e) => {
    e.preventDefault();
    const name = e.target.files[0].name;

    const formData = new FormData();
    formData.append("file", e.target.files[0]);

    this.setState({ loading: true });

    this.pictureService.uploadImage(formData).then((res) => {
      this.setState({
        attachmentId: res?.data[0]._id,
        attachmentName: name,
        loading: false,
      });
    });
  };

  solveTicket = () => {
    this.ticketService.solveTicket(this.state.data._id).then(async (res) => {
      if (res.status === 200) {
        this.loadData();
        this.closeSolveTicket();
      }
    });
  };

  sendMessage = () => {
    this.ticketMessageService
      .sendMessage(
        this.state.data._id,
        this.state.message,
        this.state.attachmentId
      )
      .then(async (res) => {
        if (res.status === 201) {
          this.setState({
            message: "",
            attachmentId: null,
            attachmentName: "",
          });
          this.loadMessages();
        }
      });
  };

  openAttachment = (id) => {
    this.setState({ loading: true });
    this.pictureService.get(id).then((res) => {
      const url = res.data.link;
      window.open(url, "_blank");
      this.setState({ loading: false });
    });
  };

  openSolveTicket = () => {
    this.setState({
      loading: true,
      openSolve: true,
    });
  };

  closeSolveTicket = () => {
    this.setState({
      loading: false,
      openSolve: false,
    });
  };

  render() {
    const handleImageClick = (event) => {
      if (this.state.attachmentId == null)
        this.hiddenPictureInput.current.click();
    };

    return (
      <Fragment>
        <div className={`${this.state.loading && "main-overlay"}`}></div>

        <div className="main-content">
          <div>
            <NavLink to="/supports" onClick={this.handleCloseSideBar}>
              <span style={{ color: "black", fontSize: "16px" }}>
                <BsArrowLeft style={{ marginBottom: "3px" }} /> Go Back
              </span>
            </NavLink>
          </div>
          <span style={{ color: "#111111", fontSize: "20px" }}>
            <h2>Support Ticket Detail</h2>
          </span>
          <div className="support-detail-cols" style={{ display: "flex" }}>
            <div className="w-100">
              <div
                style={{
                  border: "1px solid #EEEEEE",
                  borderRadius: "8px",
                }}
              >
                <div style={{ margin: "16px" }}>
                  <div className="text1">
                    Request #{this.state.data.requestId}
                  </div>
                  <div className="text2" style={{ marginBottom: "4px" }}>
                    {this.state.data.subject}
                  </div>
                  <div className="text3" style={{ marginBottom: "4px" }}>
                    {ISSUE_TYPES[this.state.data.issue]}
                  </div>
                </div>
                {this.state.messages.length > 0 && (
                  <div style={{ borderTop: "1px solid #EEEEEE" }}>
                    <div style={{ margin: "16px" }}>
                      <div style={{ display: "flex" }}>
                        {this.state.messages[0].user ? (
                          <DefaultAvatar
                            userName={this.state.messages[0].user.name}
                            width="64px"
                            height="64px"
                          >
                            {PictureHelper.profile_picture(
                              this.state.messages[0].user
                            ) ? (
                              <img
                                style={{ borderRadius: "9999px" }}
                                alt=""
                                height={64}
                                width={64}
                                src={PictureHelper.profile_picture(
                                  this.state.messages[0].user
                                )}
                              ></img>
                            ) : null}
                          </DefaultAvatar>
                        ) : (
                          <img
                            style={{ borderRadius: "9999px" }}
                            alt=""
                            height={64}
                            width={64}
                            src={`${AWS_URL}logo/user_image.jpeg`}
                          ></img>
                        )}
                        <div style={{ marginLeft: "8px", width: "100%" }}>
                          <span className="text4">
                            {this.state.messages[0].user
                              ? this.state.messages[0].user.userName
                              : this.state.data.userName}
                            {this.state.messages[0].user?.isCreatorVerified && (
                              <AccountVerifyTick width="15px"></AccountVerifyTick>
                            )}

                            <span className="text3" style={{ float: "right" }}>
                              {moment(this.state.messages[0].date).format(
                                "DD MMMM YYYY, h:mm a"
                              )}
                            </span>
                          </span>
                          <br></br>
                          <span className="text1">
                            {this.state.messages[0].message}
                            {this.state.messages[0].attachmentId != null && (
                              <p>
                                <div
                                  className="attachment-text"
                                  onClick={() =>
                                    this.openAttachment(
                                      this.state.messages[0].attachmentId
                                    )
                                  }
                                >
                                  Show Attachment
                                </div>
                              </p>
                            )}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>

              {this.state.messages.length > 1 && (
                <div className="reply-text">Replies</div>
              )}

              <div
                style={{
                  border: "1px solid #EEEEEE",
                  marginTop: "8px",
                  borderRadius: "8px",
                }}
              >
                {this.state.messages.map((message, index) => {
                  return (
                    index !== 0 && (
                      <div
                        style={{ borderTop: "1px solid #EEEEEE" }}
                        key={index}
                      >
                        <div style={{ margin: "16px" }}>
                          <div style={{ display: "flex" }}>
                            {message.user ? (
                              <DefaultAvatar
                                userName={message.user.name}
                                width="64px"
                                height="64px"
                              >
                                {message.user.profilePicture ? (
                                  <img
                                    style={{ borderRadius: "9999px" }}
                                    alt=""
                                    height={64}
                                    width={64}
                                    src={`${AWS_URL}users/${message.user._id}/profile_pic/${message.user.profilePicture}`}
                                  ></img>
                                ) : null}
                              </DefaultAvatar>
                            ) : (
                              <img
                                style={{ borderRadius: "9999px" }}
                                alt=""
                                height={64}
                                width={64}
                                src={`${AWS_URL}logo/user_image.jpeg`}
                              ></img>
                            )}
                            <div style={{ marginLeft: "8px", width: "100%" }}>
                              <span className="text4">
                                {message.user
                                  ? message.user.userName
                                  : this.state.userName}
                                {message.user?.isCreatorVerified && (
                                  <AccountVerifyTick width="15px"></AccountVerifyTick>
                                )}
                                <span
                                  className="text3"
                                  style={{ float: "right" }}
                                >
                                  {moment(message.date).format(
                                    "DD MMMM YYYY, h:mm a"
                                  )}
                                </span>
                              </span>
                              <br></br>
                              <span className="text1">
                                {message.message}
                                {message.attachmentId != null && (
                                  <p>
                                    <div
                                      className="attachment-text"
                                      onClick={() =>
                                        this.openAttachment(
                                          message.attachmentId
                                        )
                                      }
                                    >
                                      Show Attachment
                                    </div>
                                  </p>
                                )}
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    )
                  );
                })}
              </div>
              {this.state.data.status === 0 && (
                <div className={"modal-card-support"}>
                  <div className={"header-view"}>
                    <span className={"title-view"}>{this.props.title}</span>

                    <span
                      style={{ marginTop: "24px" }}
                      className={" reply-text"}
                    >
                      Reply
                    </span>
                  </div>
                  <div className={"body-view"}>
                    <div className={"input-card"}>
                      <textarea
                        placeholder="Write your response here..."
                        value={this.state.message}
                        onChange={(event) =>
                          this.setState({ message: event.target.value })
                        }
                      ></textarea>
                    </div>
                  </div>
                  <div className={"footer-view"}>
                    <div className="footer-right-button">
                      <div style={{ width: "50%" }}>
                        <div
                          onClick={handleImageClick}
                          style={{
                            color: "#2E69FF",
                            fontSize: "16px",
                            fontWeight: "400",
                            cursor: "pointer",
                          }}
                        >
                          {this.state.attachmentId != null
                            ? this.state.attachmentName
                            : "Upload attachment"}
                        </div>
                        <input
                          type="file"
                          ref={this.hiddenPictureInput}
                          onChange={this.onSubmit}
                          style={{ display: "none" }}
                        />
                      </div>
                      <div className="right" style={{ width: "50%" }}>
                        <RoundButton
                          type="red"
                          onClick={this.sendMessage}
                          disabled={this.state.loading}
                        >
                          Send Reply
                        </RoundButton>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
            <div className="submit-box">
              <div style={{ margin: "16px" }}>
                <div style={{ fontWeight: "600" }} className="text3">
                  {this.state.data.emailAddress}
                </div>
                <div style={{ fontWeight: "600" }} className="text3">
                  {this.state.data.name}
                </div>
                <div style={{ marginBottom: "12px" }} className="text3">
                  Submittend this request
                </div>
                <div style={{ marginBottom: "4px" }} className="text3">
                  Status
                </div>
                {this.state.data.status === 1 && (
                  <Pill type="solved" data="Solved" />
                )}
                {this.state.data.status === 0 && (
                  <Pill type="open" data="Open" />
                )}
                <br></br>
                <div style={{ marginBottom: "12px" }}></div>
                {this.state.data.status === 0 && (
                  <div style={{ width: "100%" }}>
                    <RoundButton
                      type="longGreen"
                      onClick={this.openSolveTicket}
                    >
                      Solve Ticket
                    </RoundButton>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>

        {this.state.openSolve && (
          <ModalSolveTicket
            onClose={this.closeSolveTicket}
            onSolve={this.solveTicket}
            title="Solve this ticket?"
            subtitle="Are you sure to deny flag this ticket as solved? Please make sure that user already have the answer that they’re looking for. If you flag this ticket as solved, both you and user will not able to continue to send messages to each other."
          />
        )}
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(SupportDetail);
